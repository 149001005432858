export const hubSpot = () => {
  const id = 'hs-script-loader';

  const el = document.getElementById(id);

  if (el) return;

  const bodyEl = document.getElementsByTagName('body')[0];

  const script = document.createElement('script');
  const url = '//js.hs-scripts.com/1794004.js';

  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', id);
  script.setAttribute('async', true);
  script.setAttribute('defer', true);
  script.setAttribute('src', url);

  bodyEl.appendChild(script);
};

export const appCues = () => {
  const id = 'app-cues';
  const el = document.getElementById(id);

  if (el) return;
  const bodyEl = document.getElementsByTagName('body')[0];

  const script = document.createElement('script');
  const url = '//fast.appcues.com/41585.js';

  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', id);
  script.setAttribute('async', true);
  script.setAttribute('defer', true);
  script.setAttribute('src', url);

  bodyEl.appendChild(script);
};

export const hotjarScript = () => {
  const id = 'hotjar';
  const el = document.getElementById(id);

  if (el) return;

  const headEl = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', id);

  script.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1157680,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;

  if (headEl.firstChild) {
    headEl.insertBefore(script, headEl.firstChild);
    return;
  }

  headEl.appendChild(script);
};

const addScript = (id, src) => new Promise((resolve, reject) => {
  const element = document.getElementById(id);

  if (element) {
    resolve(true);
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', id);
  script.setAttribute('src', src);

  script.addEventListener('load', resolve);
  script.addEventListener('error', () => reject(new Error(`Error loading ${id}.`)));
  script.addEventListener('abort', () => reject(new Error(`${id} loading aborted.`)));

  document.getElementsByTagName('head')[0].appendChild(script);
});

export const addLinkedInScript = () => {
  const id = 'linkedinAuth';
  const src = '//platform.linkedin.com/in.js?async=true';
  return addScript(id, src);
};

export const addGoogleScript = () => {
  const id = 'googleAuth';
  const src = '//apis.google.com/js/client:platform.js';
  return addScript(id, src);
};

export const addFacebookScript = () => {
  const id = 'facebookAuth';
  const src = 'https://connect.facebook.net/en_US/sdk.js';

  return addScript(id, src);
};

export const addMeetingScript = () => {
  const id = 'meetingScript';
  const src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';

  return addScript(id, src);
};