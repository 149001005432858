import superagent from 'superagent';

function pingTo(path) {
  const checkStatus = (response) => {
    if (response.status === 200) {
      return response;
    }

    const error = new Error(response.statusText);

    error.response = response;
    throw error;
  };

  const parseText = (response) => response.text;

  return superagent
    .get(`${path}?${new Date().getTime()}`)
    .then(checkStatus)
    .then(parseText);
}

/**
 * @property {string|object} currentVersion - The current version of assets
 * @property {function} setCurrentVersion - Set current version
 * @property {function} turnOn - Turns on
 * @property {function} turnOff - Turns off
 * @property {function} isPinging - Current observer status, does it start or not
 * @property {function} start - Starts a loop
 * @property {function} stop - Stops a loop
 */

/**
 * @param {string} target - URL
 * @param {string} version - initial version
 * @param {number} delay - Time to next call (in milliseconds)
 * @param {function} onUpdate - Callback, fire when a version is updated
 * @param {function} onReject - Callback, fire when request was failed
 * @returns {Observer}
 */
export default function versionObserver({
  target, version: initVersion, delay, onUpdate, onReject
}) {
  let enabled = false;
  let lastPingAt = new Date();
  let pingServerTimerId = null;
  let version = initVersion;

  const isEnable = () => enabled;
  const setLastPing = () => {
    lastPingAt = new Date();
  };
  const isTimeToPing = () => {
    const currentDate = new Date();
    const tenSecondsAgoFromNow = currentDate.setSeconds(currentDate.getSeconds() - 10);

    return tenSecondsAgoFromNow >= lastPingAt.getTime();
  };
  const pingServer = () => {
    if (!isEnable() || !isTimeToPing()) {
      return;
    }

    setLastPing();

    pingTo(target)
      .then((body) => {
        if (!version) {
          version = body;
        } else if (version !== body) {
          onUpdate(body);
        }
      })
      .catch(onReject);
  };

  const turnOn = () => {
    enabled = true;
  };
  const turnOff = () => {
    enabled = false;
  };
  const start = () => {
    pingServerTimerId = setInterval(pingServer, delay);
  };
  const stop = () => pingServerTimerId && clearInterval(pingServerTimerId);
  const isPinging = () => !!pingServerTimerId;
  const currentVersion = () => version;
  const setCurrentVersion = (value) => {
    version = value;
  };

  return Object.freeze({
    currentVersion, setCurrentVersion, isPinging, turnOn, turnOff, start, stop
  });
}
