import isArray from 'lodash/isArray';

/**
 * Get query params from string
 * @param  {string} query url search string
 * @return {object} Params from query string
 */
export const getQueryStringParams = (query = '') => {
  if (!query || typeof query !== 'string' || !query.trim()) {
    return {};
  }

  const queryString = /^[?#]/.test(query) ? query.slice(1) : query;
  const paramsArray = queryString.split('&');

  return paramsArray
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      const decodedValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

      const prevValues = params[key];
      if (prevValues) {
        params[key] = isArray(prevValues) ? [
          ...prevValues,
          decodedValue,
        ] : [
          prevValues,
          decodedValue,
        ];

        return params;
      }

      params[key] = decodedValue;
      return params;
    }, {});
};

/**
 * serialize - Serialize params to query string
 *
 * @param  {object} params Query params
 * @return {string}        Query params which been serialized
 */
export const serializeParamsToQueryString = (params = {}, hideArrayKeys = false) => {
  const str = [];

  if ((params && typeof params !== 'object') || Array.isArray(params)) {
    return '';
  }

  for (const key in params) {
    const encodedKey = encodeURIComponent(key);
    const value = params[key];

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      str.push(`${encodedKey}=${encodeURIComponent(value)}`);
      continue;
    }

    for (const subKey in value) {
      const encodedSubKey = encodeURIComponent(subKey);
      const subValue = value[subKey];

      if (typeof subValue === 'string' || typeof subValue === 'number' || typeof subValue === 'boolean') {
        const queryArrayString = hideArrayKeys
          ? `${encodedKey}[]=${encodeURIComponent(subValue)}`
          : `${encodedKey}[${encodedSubKey}]=${encodeURIComponent(subValue)}`;

        str.push(queryArrayString);
        continue;
      }

      // if(!Array.isArray(value)) {
      //   continue;
      // }

      for (const subElementKey in subValue) {
        const subElement = subValue[subElementKey];

        if (typeof subElement === 'string' || typeof subElement === 'number') {
          const querySubArrayString = hideArrayKeys
            ? `${encodedKey}[][${subElementKey}]=${encodeURIComponent(subElement)}`
            : `${encodedKey}[${encodedSubKey}][]=${encodeURIComponent(subElement)}`;
          str.push(querySubArrayString);
          continue;
        }

        Object.keys(subElement).forEach((subInnerKey) => {
          const subInnerValue = subElement[subInnerKey];
          const queryInnerSubArrayStr = `${encodedKey}[${encodedSubKey}][][${subInnerKey}]=${encodeURIComponent(subInnerValue)}`;

          str.push(queryInnerSubArrayStr);
        });
      }
    }
  }

  return str.join('&');
};
