/* eslint-disable default-param-last */
import isEmpty from 'lodash/isEmpty';
import {
  isAfter,
  isBefore,
  isSameMinute,
  isSameDay,
  getHours,
  getMinutes,
  set,
  add,
  differenceInMinutes,
  startOfDay,
} from 'date-fns';
import globalConst from '../constants';

export const isNumeric = (value = '') => /^-?\d+$/.test(value);

export const loginForm = (values, tr = {}) => {
  const errors = {};

  if (!/.+@.+\..+/.test(values.email)) {
    errors.email = tr.form.emailNotValid;
  }

  if (isEmpty(values.email)) {
    errors.email = tr.form.emailRequired;
  }

  if (isEmpty(values.password)) {
    errors.password = tr.form.passwordRequired;
  }

  return errors;
};

export const signupForm = (values, tr = {}) => {
  const errors = {};

  if (!values.first_name) {
    errors.firstName = tr.form.firstNameRequired;
  }

  if (!values.last_name) {
    errors.lastName = tr.form.lastNameRequired;
  }

  if (!/.+@.+\..+/.test(values.email)) {
    errors.email = tr.form.emailNotValid;
  }

  const emailLabel = values.email && values.email.substring(0, values.email.indexOf('@'));
  if (isNumeric(emailLabel)) {
    errors.email = tr.form.emailNotValidNumber;
  }

  if (!values.email) {
    errors.email = tr.form.emailRequired;
  }

  if (values.company_name && values.company_name.length < 4) {
    errors.companyName = tr.form.titleErrorSize;
  }

  if (!values.company_name) {
    errors.companyName = tr.form.required;
  }

  if (!values.password) {
    errors.password = tr.form.passwordRequired;
  }

  if (isEmpty(values.language)) {
    errors.language = tr.form.required;
  }

  return errors;
};

/**
 * Validation for shift create/update
 * @param {object} values
 * @param {object} tr
 */
export const shiftCreateForm = (values, tr = {}) => {
  const isDraft = values.status === globalConst.DRAFT_STATUS;
  const isAutoApproveType = values.scheduleType === globalConst.AUTO_APPROVE_TYPE;
  const isManualApproveType = values.scheduleType === globalConst.MANUAL_APPROVE_TYPE;
  const isAssignType = values.scheduleType === globalConst.ASSIGN_TYPE;

  const errors = {};

  if (!values.title) {
    errors.title = tr.form.titleShiftRequired;
  }

  if (values.title.length > 255) {
    errors.title = tr.form.titleShiftSizeMax;
  }

  if (!values.organization.id) {
    errors.organization = tr.form.organizationRequired;
  }

  if (!values.location) {
    errors.location = tr.form.locationRequired;
  }

  if (values.timebreak) {
    const startTimeBreak = startOfDay(values.timebreak);
    const breakInMinutes = differenceInMinutes(values.timebreak, startTimeBreak);
    if ((!values.isNight && breakInMinutes >= differenceInMinutes(values.finishTime, values.startTime))
    || (values.isNight && breakInMinutes >= differenceInMinutes(add(values.finishTime, { days: 1 }), values.startTime))
    ) {
      errors.timebreak = tr.form.pauseError;
    }
  }

  if (isSameMinute(values.startTime, values.finishTime)) {
    errors.date = tr.form.gigTimeError;
  }

  if (!values.roleCount && isEmpty(values.selectedRoles)) {
    errors.roleEntity = tr.form.required;
  }

  if (isEmpty(values.roleIds) && isEmpty(values.selectedRoles)) {
    errors.roleEntity = tr.form.roleRequired;
  }

  if (!values.editRoleId && values.roleCount && values.roleCount > globalConst.MAX_SHIFT_ROLE_COUNT) {
    errors.roleEntity = tr.form.roleCountSizeMax;
  }

  if (!values.editRoleId && !isEmpty(values.workerIds) && values.workerIds.length > globalConst.MAX_SHIFT_ROLE_COUNT) {
    errors.workerIds = tr.form.roleCountSizeMax;
  }

  if (!values.editRoleId && !isEmpty(values.roleIds) && !isDraft) {
    if ((isAutoApproveType || isManualApproveType) && !values.open && values.workerIds.length < values.roleCount) {
      const needed = values.roleCount;
      errors.workerIds = tr.form.workerRequired.replace('$workerCount', needed);
    }

    if (isAssignType && values.workerIds.length !== values.roleCount) {
      const needed = values.roleCount;
      errors.workerIds = tr.form.workerAssignRequired.replace('$workerCount', needed);
    }
  }

  if (!isEmpty(values.selectedRoles)) {
    values.selectedRoles.forEach((roleEntity) => {
      if (roleEntity.workerIds.length > globalConst.MAX_SHIFT_ROLE_COUNT) {
        errors[roleEntity.entityId] = tr.form.roleCountSizeMax;
      }

      if (!roleEntity.count) {
        errors[roleEntity.entityId] = tr.form.required;
      }

      if (roleEntity.count > globalConst.MAX_SHIFT_ROLE_COUNT) {
        errors[roleEntity.entityId] = tr.form.roleCountSizeMax;
      }

      // Validations for create new roles
      if (!isEmpty(roleEntity.ids) && !roleEntity.id && !isDraft) {
        if ((isAutoApproveType || isManualApproveType)
          && !values.open
          && roleEntity.workerIds.length < roleEntity.count
        ) {
          const needed = roleEntity.count;
          errors[roleEntity.entityId] = tr.form.workerRequired.replace('$workerCount', needed);
        }

        if (isAssignType && roleEntity.workerIds.length !== roleEntity.count) {
          const needed = roleEntity.count;
          errors[roleEntity.entityId] = tr.form.workerAssignRequired.replace('$workerCount', needed);
        }
      }

      // Validation for update exist
      if (!isEmpty(roleEntity.ids) && roleEntity.id && (!isDraft || values.action === globalConst.PUBLISH_STR)) {
        const countAccepted = roleEntity.shiftWorkers
          .filter((worker) => worker.gig_status === globalConst.ACCEPTED_STATUS).length;
        const countInvited = roleEntity.shiftWorkers
          .filter((worker) => worker.gig_status === globalConst.INVITED_STATUS).length;
        const countAssigned = roleEntity.workerIds.length;
        const total = countAccepted + countInvited + countAssigned;

        if ((isAutoApproveType || isManualApproveType)
          && !values.open
          && total < roleEntity.count
        ) {
          const needed = roleEntity.count - total;
          errors[roleEntity.entityId] = tr.form.workerRequired.replace('$workerCount', needed);
        }

        if (isAssignType && ((countAccepted + countAssigned) !== roleEntity.count)) {
          const needed = roleEntity.count - (countAccepted + countAssigned);
          errors[roleEntity.entityId] = tr.form.workerAssignRequired.replace('$workerCount', needed);
        }
      }
    });
  }

  const prepareDate = set(values.date, {
    hours: getHours(values.startTime),
    minutes: getMinutes(values.startTime)
  });

  const dateUntilIsBefore = values.dateUntil && isBefore(prepareDate, values.dateUntil);
  const dateUntilIsSame = values.dateUntil && isSameMinute(prepareDate, values.dateUntil);
  if (dateUntilIsBefore || dateUntilIsSame) {
    errors.dateUntil = tr.form.gigAcceptError;
  }

  if (values.repeat && values.repeat.show) {
    const isDayFinishAt = (values.repeat.type === globalConst.DAY_STR)
      && (values.repeat.endType === globalConst.END_TYPE_ON);
    const isDayOccurrences = (values.repeat.type === globalConst.DAY_STR)
      && (values.repeat.endType === globalConst.END_TYPE_AFTER);
    const notDayType = (values.repeat.type !== globalConst.DAY_STR);

    if (isDayOccurrences && !values.repeat.occurrences) {
      errors.repeat = tr.form.repeatOccurrencesError;
    }

    const dateIsAfterRepeat = !values.repeat.finish || isAfter(values.date, values.repeat.finish)
    || isSameDay(values.date, values.repeat.finish);
    if (dateIsAfterRepeat && (isDayFinishAt || notDayType)) {
      errors.repeat = tr.form.gigDateError;
    }

    if (values.repeat.type === globalConst.WEEK_STR && isEmpty(values.repeat.days)) {
      errors.repeatDay = tr.form.selectDay;
    }
  }

  if (values.description && values.description.length > globalConst.MAX_SHIFT_DESCRIPTION) {
    errors.description = tr.form.errorLengthMax.replace('$count', globalConst.MAX_SHIFT_DESCRIPTION);
  }

  if (values.hiddenDesc && values.hiddenDesc.length > globalConst.MAX_SHIFT_DESCRIPTION) {
    errors.hiddenDesc = tr.form.errorLengthMax.replace('$count', globalConst.MAX_SHIFT_DESCRIPTION);
  }

  if (!isEmpty(values.gigCategoryInfo)) {
    const isHours = values.gigCategoryInfo.timeframe_type === globalConst.HOURS_STR;
    if (isHours && isSameMinute(values.gigCategoryInfo.startTime, values.gigCategoryInfo.finishTime)) {
      errors.gigCategoryInfo = tr.form.sameTimeError;
    }

    if (isHours && !values.isNight && (
      isBefore(values.gigCategoryInfo.startTime, values.startTime)
      || isAfter(values.gigCategoryInfo.finishTime, values.finishTime)
    )) {
      errors.gigCategoryInfo = tr.form.shiftTypeTimeError;
    }
  }

  return errors;
};

export const worklogForm = (values, tr = {}) => {
  const errors = {};

  if (values.distance && values.distance < 0) {
    errors.distance = tr.form.valuePositive;
  }

  return errors;
};

export const forgotForm = (values, tr = {}) => {
  const errors = {};
  const {
    email,
  } = values;

  if (!/.+@.+\..+/.test(email)) {
    errors.email = tr.form.emailNotValid;
  }

  if (isEmpty(email)) {
    errors.email = tr.form.emailRequired;
  }

  return errors;
};

export const pwConfirmation = (values, tr = {}) => {
  const errors = {};

  if (values.password.length < 6) {
    errors.password = tr.form.passwordErrorSize;
  }

  if (isEmpty(values.password)) {
    errors.password = tr.form.passwordRequired;
  }

  if (values.password !== values.password_confirmation) {
    errors.confirmPsw = tr.form.passwordConfNotValid;
  }

  if (values.password_confirmation.length < 6) {
    errors.confirmPsw = tr.form.passwordErrorSize;
  }

  if (isEmpty(values.password_confirmation)) {
    errors.confirmPsw = tr.form.passwordConfRequired;
  }

  if (isEmpty(values.reset_password_token)) {
    errors.token = tr.form.tokenWrong;
  }

  return errors;
};

/**
 * Manager invitation pw confirmation
 * @param {object} values
 * @param {object} tr
 */
export const pwManagerConfirmation = (values, tr = {}) => {
  const errors = {};

  if (values.password.length < 6) {
    errors.password = tr.form.passwordErrorSize;
  }

  if (isEmpty(values.password)) {
    errors.password = tr.form.passwordRequired;
  }

  if (values.password !== values.password_confirmation) {
    errors.confirmPsw = tr.form.passwordConfNotValid;
  }

  if (values.password_confirmation.length < 6) {
    errors.confirmPsw = tr.form.passwordErrorSize;
  }

  if (isEmpty(values.password_confirmation)) {
    errors.confirmPsw = tr.form.passwordConfRequired;
  }

  if (isEmpty(values.invitation_token)) {
    errors.token = tr.form.tokenWrong;
  }

  return errors;
};

export const managerForm = (values, tr) => {
  const errors = {};

  if (!/.+@.+\..+/.test(values.email)) {
    errors.email = tr.form.emailNotValid;
  }

  if (!values.email) {
    errors.email = tr.form.emailRequired;
  }

  if (values.first_name.length > globalConst.MAX_TITLE_LENGTH) {
    errors.firstName = tr.form.titleErrorSizeMax;
  }

  if (!values.first_name) {
    errors.firstName = tr.form.firstNameRequired;
  }

  if (values.last_name.length > globalConst.MAX_TITLE_LENGTH) {
    errors.lastName = tr.form.titleErrorSizeMax;
  }

  if (!values.last_name) {
    errors.lastName = tr.form.lastNameRequired;
  }

  if (values.phone.length > globalConst.MAX_TITLE_LENGTH) {
    errors.phone = tr.form.titleErrorSizeMax;
  }

  if (!values.all_organizations_access && isEmpty(values.organizations)) {
    errors.organizations = tr.form.organizationRequired;
  }

  if (!values.manager_type) {
    errors.type = tr.form.required;
  }

  return errors;
};

export const workerForm = (values, tr = {}) => {
  const errors = {};

  if (!/.+@.+\..+/.test(values.email)) {
    errors.email = tr.form.emailNotValid;
  }

  if (!values.email) {
    errors.email = tr.form.emailRequired;
  }

  if (values.firstName.length > globalConst.MAX_TITLE_LENGTH) {
    errors.firstName = tr.form.titleErrorSizeMax;
  }

  if (!values.firstName) {
    errors.firstName = tr.form.firstNameRequired;
  }

  if (values.lastName.length > globalConst.MAX_TITLE_LENGTH) {
    errors.lastName = tr.form.titleErrorSizeMax;
  }

  if (!values.lastName) {
    errors.lastName = tr.form.lastNameRequired;
  }

  if (isEmpty(values.roles)) {
    errors.roles = tr.form.roleRequired;
  }

  return errors;
};

export const profileForm = (values, tr = {}) => {
  const errors = {};

  if (values.first_name.length > globalConst.MAX_TITLE_LENGTH) {
    errors.firstName = tr.form.titleErrorSizeMax;
  }

  if (!values.first_name) {
    errors.firstName = tr.form.firstNameRequired;
  }

  if (values.last_name.length > globalConst.MAX_TITLE_LENGTH) {
    errors.lastName = tr.form.titleErrorSizeMax;
  }

  if (!values.last_name) {
    errors.lastName = tr.form.lastNameRequired;
  }

  if (values.phone && (values.phone.length > globalConst.MAX_TITLE_LENGTH)) {
    errors.phone = tr.form.titleErrorSizeMax;
  }

  return errors;
};

export const orgForm = (values, tr = {}) => {
  const errors = {};

  if (values.title.length < 4) {
    errors.title = tr.form.titleErrorSize;
  }

  if (values.title.length > globalConst.MAX_TITLE_LENGTH) {
    errors.title = tr.form.titleErrorSizeMax;
  }

  if (!values.title) {
    errors.title = tr.form.titleRequired;
  }

  if (values.email && !/.+@.+\..+/.test(values.email)) {
    errors.email = tr.form.emailNotValid;
  }

  if (values.maximumNumberKm && values.maximumNumberKm < 0) {
    errors.maximumNumberKm = tr.form.valuePositive;
  }

  return errors;
};

export const billingForm = (values, tr = {}) => {
  const errors = {};

  if (Object.prototype.hasOwnProperty.call(values, 'companyName') && !values.companyName) {
    errors.companyName = tr.form.required;
  }

  if (!values.address) {
    errors.address = tr.form.required;
  }

  if (!values.companyName) {
    errors.companyName = tr.form.required;
  }

  if (values.code && values.code.length > 10) {
    errors.code = tr.form.errorLengthMax.replace('$count', 10);
  }

  if (!values.code) {
    errors.code = tr.form.required;
  }

  if (!values.city) {
    errors.city = tr.form.required;
  }

  if (values.btwException && (typeof values.btwRate !== 'number')) {
    errors.btwRate = tr.form.required;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'KVKNumber') && !values.KVKNumber) {
    errors.KVKNumber = tr.form.required;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'IBANNumber') && !values.IBANNumber) {
    errors.IBANNumber = tr.form.required;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'btwNumber') && !values.btwNumber) {
    errors.btwNumber = tr.form.required;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'email') && !/.+@.+\..+/.test(values.email)) {
    errors.email = tr.form.emailNotValid;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'email') && !values.email) {
    errors.email = tr.form.emailRequired;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'buyingCCEmail') && !/.+@.+\..+/.test(values.buyingCCEmail)) {
    errors.buyingCCEmail = tr.form.emailNotValid;
  }

  if (Object.prototype.hasOwnProperty.call(values, 'sellingCCEmail') && !/.+@.+\..+/.test(values.sellingCCEmail)) {
    errors.sellingCCEmail = tr.form.emailNotValid;
  }

  if (!values.paymentTermInfo) {
    errors.paymentTermInfo = tr.form.required;
  }

  if (values.paymentTermInfo > 99 || values.paymentTermInfo < 1) {
    errors.paymentTermInfo = 'Min 1, max 99';
  }

  if (Object.prototype.hasOwnProperty.call(values, 'invoiceTo') && !values.invoiceTo) {
    errors.invoiceTo = tr.form.required;
  }

  return errors;
};

export const reportForm = (values, tr = {}) => {
  const errors = {};

  if (!values.start || !values.end) {
    errors.date = tr.form.dateNotValid;
  }

  if (values.start && values.end && isAfter(values.start, values.end)) {
    errors.date = tr.form.gigDateError;
  }

  if ((values.type === globalConst.SELECTIVE_TYPE) && isEmpty(values.selective_params)) {
    errors.type = tr.form.youHaveSelect;
  }

  return errors;
};

/**
 * Validation for generate invoice form
 */
export const invoiceForm = (values, tr = {}) => {
  const errors = {};

  if (!values.startDate || !values.finishDate) {
    errors.date = tr.form.mandatoryField;
  }

  if (values.startDate && values.finishDate && isAfter(values.startDate, values.finishDate)) {
    errors.date = tr.form.gigDateError;
  }

  if (!values.process) {
    errors.process = tr.form.mandatoryField;
  }

  if (!values.type) {
    errors.type = tr.form.mandatoryField;
  }

  if (!isEmpty(values.agencyFees)) {
    const filteredFee = values.agencyFees.filter((el) => !el.amount);
    filteredFee.forEach((el) => {
      errors[el.id] = tr.form.required;
      return errors;
    });
  }

  return errors;
};

/**
 * Validate agency fee form
 */

export const agencyFeeForm = (values, tr = {}) => {
  const errors = {};
  if (!values.name) {
    errors.name = tr.form.required;
  }
  if (values.name.length > 255) {
    errors.name = tr.form.titleShiftSizeMax;
  }
  if (!values.value) {
    errors.value = tr.form.required;
  }
  if (isEmpty(values.fee)) {
    errors.fee = tr.form.required;
  }
  return errors;
};

/**
 * Validate (http://example.com)
 * @param {string} string URL to valid
 */
export const urlIsValid = (string = '') => {
  const dateRegex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/;

  return dateRegex.test(string);
};

export const brandingForm = (field = {}, tr) => {
  const { name, value } = field;
  let error = '';

  if (name === 'title' && value.length > globalConst.MAX_TITLE_LENGTH) {
    error = tr.form.errorLengthMax.replace('$count', globalConst.MAX_TITLE_LENGTH);
  }

  if (name === 'description' && value.length > globalConst.MAX_DESCRIPTION_LENGTH) {
    error = tr.form.errorLengthMax.replace('$count', globalConst.MAX_DESCRIPTION_LENGTH);
  }

  if (name === 'email' && value && (!/.+@.+\..+/.test(value))) {
    error = tr.form.emailNotValid;
  }

  if (globalConst.SOCIAL_NETWORKS.indexOf(name) !== -1 && value && !urlIsValid(value)) {
    error = 'The URL is not valid, use "https://example.com"';
  }

  if (name === 'email_template' && value.length > globalConst.MAX_LENGTH_1000) {
    error = tr.form.errorLengthMax.replace('$count', globalConst.MAX_LENGTH_1000);
  }

  return error;
};

export const validateRule = (rule, allRules) => allRules
  .find((ortRule) => {
    const isStartTimeError = (rule.start_at > ortRule.start_at) && (rule.start_at < ortRule.finish_at);
    const isFinishTimeError = (rule.finish_at > ortRule.start_at) && (rule.finish_at < ortRule.finish_at);
    const isNewRuleIncludeOld = (rule.start_at <= ortRule.start_at) && (rule.finish_at >= ortRule.finish_at);
    return isStartTimeError || isFinishTimeError || isNewRuleIncludeOld;
  });

/**
 * Check if time format equal '23:59'
 * @param {string} time Time to valid
 */
export const timeIsValid = (time) => /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(time);

/**
 * Validate (YYYY-MM-DD), with a year between 1900 and 2199
 * @param {string} date Date to valid
 */
export const dateIsValid = (date) => {
  const dateRegex = /^((19|20|21)\d{2})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;

  return dateRegex.test(date);
};